<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Save All To One PDF</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group
                  :validator="$v.form.period"
                  label="Period"
                  :use-horizontal="false"
                >
                  <b-form-input
                    type="month"
                    placeholder="Enter period"
                    autocomplete="off"
                    v-model="form.period"
                  ></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button
                type="submit"
                size="sm"
                variant="success"
                class="float-right"
              >
                Save File
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    form: {
      period: null,
    },
  }),
  validations: {
    form: {
      period: { required },
    },
  },
  methods: {
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText = "You are about to save these files. Are you sure ?",
        _okText = "Yes, Save",
        _action = "apis/download",
        _url = "/transaction/invoice/combined";

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Cancel",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch(_action, {
              url: _url,
              params: self.form,
            })
            .then((response) => {
              dialog.close();
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {

                let fileURL = window.URL.createObjectURL(new Blob([response])),
                  fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", "combined-file-" + self.form.period + ".pdf");
                document.body.appendChild(fileLink);

                fileLink.click();
              }
            });
        });
    },
  },
};
</script>
